import { ReactNode, useState } from 'react'
import { View } from 'react-native'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { colors, presets } from '../../../styles/colors'
import { Button_C } from '../../Base/Button/Button'
import { Heading_C } from '../../Base/Heading/Heading'
import { Icon_C } from '../../Base/Icon/Icon'
import { chevronIcon } from '../../Base/Icon/preset-icon-props'
import { Pressable_C } from '../../Base/Pressable/Pressable'
import { ExpandContainer_C } from '../../Layout/ExpandContainer/ExpandContainer'
import { tabletBreakpoint } from '../../../services/styles/dependencies/style-constants'
import { Text_C } from '../../Base/Text/Text'
import { textSizes } from '../../../styles/text-sizes'

type Props = {
  filters: FiltersLabelValuePair[]
  dateFilters: FiltersLabelValuePair[]
  ratingSlider: FiltersLabelValuePair
  isExpanded?: boolean
  useSubmitButton?(): void
}

export type FiltersLabelValuePair = {
  label: string
  filter: ReactNode
  isCalendarPicker?: boolean
}
export function FiltersContainer_C({
  filters,
  dateFilters,
  ratingSlider,
  useSubmitButton,
  isExpanded: initialIsExpanded
}: Props) {
  const [isExpanded, setIsExpanded] = useState(initialIsExpanded)
  const styles = useBreakpointStyles({
    styles: breakpointStyles
  })

  const filterHeader = (
    <Pressable_C
      onPress={() => setIsExpanded((prev) => !prev)}
      style={{
        elem: {
          flexDirection: 'row',
          gap: 20,
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 20
        }
      }}
    >
      <Heading_C styleType="h4">Filter</Heading_C>
      <Icon_C
        {...{
          ...chevronIcon,
          style: {
            ...chevronIcon.style,
            ...(isExpanded && { transform: [{ rotate: '180deg' }] })
          }
        }}
      />
    </Pressable_C>
  )

  const filterBody = (
    <ExpandContainer_C isExpanded={isExpanded}>
      <View style={styles.innerContainer}>
        <View style={styles.filters}>
          {dateFilters?.map(({ label, filter, isCalendarPicker }) => (
            <View
              key={label}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                maxWidth: 200,
                ...(isCalendarPicker && { zIndex: 1 })
              }}
            >
              <Text_C
                style={{
                  fontWeight: '600SemiBold',
                  ...textSizes.size3,
                  minWidth: 60
                }}
              >
                {label}
              </Text_C>

              {filter}
            </View>
          ))}
        </View>

        <View style={{ flex: 1 }}>
          <Text_C
            style={{
              textAlign: 'center',
              fontWeight: '600SemiBold',
              ...textSizes.size3,
              minWidth: 60
            }}
          >
            {ratingSlider.label}
          </Text_C>

          {ratingSlider.filter}
        </View>

        <View style={styles.filters}>
          {filters.map(({ label, filter }) => (
            <View
              key={label}
              style={{
                gap: 10,
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                maxWidth: 200
              }}
            >
              {filter}
            </View>
          ))}
        </View>

        {useSubmitButton && (
          <Button_C
            style={{ elem: styles.searchButton }}
            styleType="formButton"
            onPress={() => useSubmitButton()}
          >
            Search
          </Button_C>
        )}
      </View>
    </ExpandContainer_C>
  )

  return (
    <View style={styles.filtersContainer}>
      {filterHeader}
      {filterBody}
    </View>
  )
}
const breakpointStyles = createStyles({
  filtersContainer: {
    base: {
      backgroundColor: colors.gray50,
      border: 'solid',
      borderWidth: 1,
      borderRadius: 8,
      borderColor: presets.border,
      width: '100%'
    },
    [tabletBreakpoint]: {
      width: 490
    }
  },
  innerContainer: {
    gap: 20,
    marginHorizontal: 20,
    paddingTop: 20,
    marginBottom: 20,
    borderTopWidth: 1,
    borderTopColor: presets.border
  },
  filters: {
    base: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: 30
    }
  },
  filter: {
    base: {
      gap: 10,
      flexDirection: 'row',
      alignItems: 'center',
      width: 200
    }
  },
  searchButton: { marginTop: 40 }
})
