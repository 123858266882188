import { getPlacementPercents, removeLeadingSlash, toCapitalized } from '../../../helper-functions'
import { convertAsset } from '../../../utils/assets'
import { fetchData } from '../api-fetch-request'
import {
  HorseFetchResponse,
  ResponseHorsePerformance,
  ResponsePerformanceStats
} from '../data-types/fetch-response-types/horse-response.type'
import { Horse } from '../data-types/local-data-types/horse.type'
import { horseDesignMock } from '../mock-responses/horse-design-mock'
import { horseCompare } from '../validation-compare-objects/horse-compare'
import { LatestResult } from './../data-types/local-data-types/horse.type'

export type HorseResponse = {
  horse: Horse
}

type GetHorseParams = {
  horseSlug: string
  useDesignMock?: boolean
}
export async function getHorseData({
  horseSlug,
  useDesignMock
}: GetHorseParams): Promise<HorseResponse> {
  if (useDesignMock) {
    return horseDesignMock
  }

  const endpoint = `racing/horses/${horseSlug}`

  return fetchData<HorseResponse, HorseFetchResponse>({
    endpoint,
    convertResponse,
    validationCompareObject: horseCompare
  })

  function convertResponse(horsePayload: HorseFetchResponse): HorseResponse {
    const {
      horse,
      performance_records,
      upcoming_races,
      current_season_summary,
      last_season_summary,
      lifetime_summary
    } = horsePayload

    const {
      id,
      bm_sire,
      breeder,
      colour,
      dam,
      foaling_date,
      formatted_name,
      gait,
      freezebrand,
      microchip,
      gender,
      sire,
      slug,
      futurity_eligibility,
      assessment,
      hwoe_assessment,
      owner,
      trainer
    } = horse

    const horseResponse: HorseResponse = {
      horse: {
        id,
        name: formatted_name,
        slug: removeLeadingSlash(slug),
        colour: toCapitalized(colour),
        sex: toCapitalized(gender),
        birthDate: new Date(foaling_date),

        sire: sire
          ? {
              name: sire.name,
              slug: !sire.slug ? undefined : removeLeadingSlash(sire.slug)
            }
          : undefined,
        dam: dam
          ? {
              name: dam.name,
              slug: !dam.slug ? undefined : removeLeadingSlash(dam.slug)
            }
          : undefined,
        broodmareSire: bm_sire
          ? {
              name: bm_sire.name,
              slug: !bm_sire.slug ? undefined : removeLeadingSlash(bm_sire.slug)
            }
          : undefined,

        owners: owner.display_name ?? undefined,
        breeder: breeder,
        trainer: !trainer
          ? undefined
          : {
              shortName: trainer.mobile_display_name ?? undefined,
              name: trainer.display_name,
              slug: trainer.slug ? removeLeadingSlash(trainer.slug) : undefined
            },

        gait: toCapitalized(gait),
        freezebrand: freezebrand ?? undefined,
        microchip: microchip ?? undefined,
        class: assessment,
        hwoe: hwoe_assessment ?? undefined, //Highest Win Over Earnings

        futurityEligibility: !futurity_eligibility
          ? undefined
          : futurity_eligibility.map((asset) => convertAsset(asset)),

        lifetimePerformance: {
          summary: statsToSummary(lifetime_summary),
          ...lifetime_summary,
          bestWinMileRate: lifetime_summary.best_win_mile_rate,
          ...getPlacementPercents({
            starts: lifetime_summary.starts,
            wins: lifetime_summary.wins,
            places: lifetime_summary.seconds + lifetime_summary.thirds
          })
        },
        thisSeasonPerformance: {
          summary: statsToSummary(current_season_summary),
          ...current_season_summary,
          bestWinMileRate: current_season_summary.best_win_mile_rate,
          ...getPlacementPercents({
            starts: current_season_summary.starts,
            wins: current_season_summary.wins,
            places: current_season_summary.seconds + current_season_summary.thirds
          })
        },
        lastSeasonPerformance: {
          summary: statsToSummary(last_season_summary),
          ...last_season_summary,
          bestWinMileRate: last_season_summary.best_win_mile_rate,
          ...getPlacementPercents({
            starts: last_season_summary.starts,
            wins: last_season_summary.wins,
            places: last_season_summary.seconds + last_season_summary.thirds
          })
        },

        upcomingRaces: upcoming_races.map((r) => {
          return {
            meetingDate: new Date(r.meeting.date),
            isTrial: r.meeting.is_trial,
            barrier: r.barrier,
            odds: r.woo_average_odds ?? undefined,
            meetingDayPhase: r.meeting.day_night_twilight,
            track: {
              name: r.track.desktop_display_name,
              shortName: r.track.mobile_display_name,
              slug: removeLeadingSlash(r.track.slug)
            },
            race: {
              number: r.race.number,
              distance: r.race.distance,
              startType: r.race.start_type,
              mileRate: r.race.time_mile_rate ?? undefined
            },
            trainer: {
              name: r.trainer.display_name,
              shortName: r.trainer.mobile_display_name,
              slug: removeLeadingSlash(r.trainer.slug)
            },
            driver: !r.driver
              ? undefined
              : {
                  name: r.driver.display_name,
                  shortName: r.driver.mobile_display_name,
                  slug: removeLeadingSlash(r.driver.slug)
                }
          }
        }),

        latestResults: performance_records.map(mapHorsePerformanceRecord)
      }
    }

    return horseResponse

    function statsToSummary(stats: ResponsePerformanceStats): string {
      const { starts, seconds, thirds, wins } = stats
      return `${starts}-${wins}-${seconds}-${thirds}`
    }
  }
}

export function mapHorsePerformanceRecord(performance: ResponseHorsePerformance) {
  const {
    driver,
    formatted_handicap,
    margin,
    meeting,
    place,
    prizemoney,
    race,
    starting_price,
    track,
    trainer
  } = performance
  const horse: LatestResult = {
    handicap: formatted_handicap,
    margin,
    meetingDate: meeting.date,
    isTrial: meeting.is_trial,
    meetingDayPhase: meeting.day_night_twilight,
    place,
    prizemoney: Number(prizemoney),
    race: {
      ...race,
      number: race.number ?? undefined,
      mileRate: race.time_mile_rate,
      startType: race.start_type,
      hasReplay: race.has_replay
    },
    startingPrice: starting_price ?? undefined,
    track: {
      name: track.desktop_display_name,
      shortName: track.mobile_display_name,
      slug: removeLeadingSlash(track.slug),
      published: track.published
    },
    trainer: !trainer
      ? undefined
      : {
          shortName: trainer.mobile_display_name,
          name: trainer.display_name,
          slug: removeLeadingSlash(trainer.slug)
        },
    driver: !driver
      ? undefined
      : {
          shortName: driver.mobile_display_name,
          name: driver.display_name,
          slug: removeLeadingSlash(driver.slug)
        }
  }
  return horse
}
