/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { fetchData } from '../api-fetch-request'
import { ProgramSearchFetchResponse } from '../data-types/fetch-response-types/program-search-response.type'
import { ProgramSearch } from '../data-types/local-data-types/program-search'

import { PerPageOption } from '../../../components/Partials/Pagination/components/PerPageSelect'
import {
  AgeOptions,
  DistanceOptions,
  GaitOptions,
  PrizeMoneyOptions,
  ProgramSearchFilter,
  SexOptions
} from '../../../components/Screens/ProgramSearch/program-search'
import { removeLeadingSlash, toCapitalized } from '../../../helper-functions'
import { shortDate } from '../../../utils/date-time'
import {
  meetingStatusMap,
  Pagination,
  raceStatusMap,
  startTypeMap
} from '../data-types/general-data-types.type'
import { programSearchCompare } from '../validation-compare-objects/program-search-compare'

export type ProgramSearchResponse = {
  programSearch: ProgramSearch
  pagination?: Pagination
}

type GetProgramSearchParams = {
  filter: ProgramSearchFilter
  activePageNumber?: number
  perPage?: number

  useDesignMock?: boolean
}
export async function getProgramSearchData(
  params: GetProgramSearchParams
): Promise<ProgramSearchResponse> {
  const {
    filter: {
      state,
      fromDate,
      toDate,
      includeTrial,
      gait,
      age,
      sex,
      prizeMoney,
      startType,
      track,
      distance,
      rating
    },
    activePageNumber,
    perPage,
    useDesignMock
  } = params

  if (useDesignMock) {
    throw 'no design mock'
  }
  const searchQuery: SearchQuery = {
    start_date: shortDate(fromDate),
    end_date: shortDate(toDate)
  }

  searchQuery.trial = includeTrial ? 'true' : 'false'
  prizeMoney && (searchQuery.prizemoney = prizeMoney)
  startType && (searchQuery.start_type = startType == 'Mobile Start' ? 'ms' : 'ss')

  track && (searchQuery.track_slug = removeLeadingSlash(track.slug))
  gait && (searchQuery.gait = gait)
  age && (searchQuery.age = age)
  sex && (searchQuery.sex = sex)
  distance && (searchQuery.distance = distance)

  const queryParams = new URLSearchParams({
    ...searchQuery,
    'pagination[limit]': `${perPage ?? 10}`,
    'pagination[page]': `${activePageNumber ?? 1}`
  })

  const ratingQuery = rating?.map((r) => `rating[]=${r}`).join('&')

  const endpoint = `racing/calendar/race-program-search/${state?.toLocaleLowerCase() || 'NSW'}?${queryParams}&${!!rating && ratingQuery}`

  return fetchData<ProgramSearchResponse, ProgramSearchFetchResponse>({
    endpoint,
    convertResponse,
    validationCompareObject: programSearchCompare
  })

  function convertResponse(
    programSearchPayload: ProgramSearchFetchResponse
  ): ProgramSearchResponse {
    const { races, pagination } = programSearchPayload

    const programSearchResponse: ProgramSearchResponse = {
      pagination: pagination
        ? {
            activePageNumber: pagination.page,
            totalPages: pagination.pages,
            perPage: pagination.limit as PerPageOption,
            totalRecords: pagination.total
          }
        : undefined,
      programSearch: {
        races: races.map((r) => {
          const {
            distance,
            name,
            race_barrier_draw_type,
            race_class,
            race_notes,
            short_name,
            start_type,
            total_prize_money,
            gait,
            rise_race_code,
            race_status,
            track: { desktop_display_name, mobile_display_name, slug },
            meeting: { day_night_twilight, date, meeting_status, nominations_close_time, is_trial }
          } = r

          return {
            name: name,
            shortName: short_name,
            raceCode: rise_race_code,
            startType: startTypeMap[start_type],
            totalPrizeMoney: total_prize_money,
            class: race_class,
            gait: toCapitalized(gait),
            distance,
            barrierDrawType: race_barrier_draw_type,
            notes: race_notes ?? undefined,
            status: raceStatusMap[race_status],
            track: {
              name: desktop_display_name,
              shortName: mobile_display_name,
              slug: removeLeadingSlash(slug)
            },
            meeting: {
              date,
              dayPhaseLetter: day_night_twilight,
              status: meetingStatusMap[meeting_status],
              nomsCloseDate: nominations_close_time ? new Date(nominations_close_time) : undefined,
              isTrial: is_trial
            }
          }
        })
      }
    }

    return programSearchResponse
  }
}

type SearchQuery = {
  start_date?: string
  end_date?: string
  track_slug?: string
  trial?: 'true' | 'false'
  prizemoney?: PrizeMoneyOptions
  start_type?: 'ms' | 'ss'
  gait?: GaitOptions
  age?: AgeOptions
  sex?: SexOptions
  distance?: DistanceOptions
}
