// cspell:word mmaaa

import { format, formatDistance } from 'date-fns'
import { formatInTimeZone, utcToZonedTime, toDate } from 'date-fns-tz'

export const shortDate = (date: Date | number) => format(date, 'yyyy-MM-dd')

export const shortDateWithSlashes = (date: Date) => format(date, 'dd/MM/yyyy')

export const yearAndMonth = (date: Date) => format(date, 'yyyy-MM')

export const humanFriendlyShortDate = (date: Date | string) => format(new Date(date), 'dd MMM yyyy')

export const humanFriendlyDate = (date: Date) => format(date, 'EE, dd MMM yyyy')

export const humanFriendlyLongDate = (date: Date) => format(date, 'EE, dd MMMM yyyy')

export const humanFriendlyDateTime = (date: Date) => format(date, 'h:mmaaa dd MMM yyyy')

export const humanFriendlyTime = (date: Date) =>
  formatInTimeZone(date, 'Australia/Sydney', 'h:mmaaa')

export const time24Hour = (date: Date) => format(date, 'HH:mm')

export const dayAndMonth = (date: Date) => format(date, 'd MMM')

export const dateByDistance = (date: string): string =>
  formatDistance(new Date(date), Date.now(), {
    addSuffix: true
  })

export const getTodaysDate = () => {
  const todaysDate = utcToZonedTime(new Date(), 'Australia/Sydney')

  return todaysDate
}

export const getFirstDayOfMonth = () => {
  const todaysDate = utcToZonedTime(new Date(), 'Australia/Sydney')
  return toDate(todaysDate.setUTCDate(1))
}
