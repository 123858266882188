import { kebabCase } from 'lodash'
import { MeetingDetailed } from '../services/data/data-types/local-data-types/meeting-detailed.type'
import { shortDate } from './date-time'

export const getRasLink = (meeting: MeetingDetailed) => {
  const basePath = 'https://hrnsw.racingandsports.com/form-guide/harness/australia'
  const trackPath = kebabCase(meeting.track.shortName)
  const datePath = shortDate(meeting.date)

  return `${basePath}/${trackPath}/${datePath}/R1`
}
