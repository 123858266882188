import { DrawerContentScrollView } from '@react-navigation/drawer'
import { DrawerActions, useNavigation } from '@react-navigation/native'
import { StyleSheet, View } from 'react-native'

import { mainMenuLinksProps } from '../MainMenu/main-menu-link-props'
import { Button_C } from '../../Base/Button/Button'
import { Link_C } from '../../Base/Link/Link'
import { PressableStyleExtension } from '../../Base/Pressable/Pressable'
import { colors, presets } from '../../../styles/colors'
import { textSizes } from '../../../styles/text-sizes'

export function DrawerContent_C() {
  const navigation = useNavigation()

  const handleOpenDrawer = () => {
    navigation.dispatch(DrawerActions.toggleDrawer())
  }

  return (
    <DrawerContentScrollView
      contentContainerStyle={{ paddingTop: 0 }}
      style={styles.container}
      bounces={false}
      stickyHeaderIndices={[0]}
    >
      <View style={styles.closeButtonContainer}>
        <Button_C
          onPress={handleOpenDrawer}
          icon={{
            name: 'cross',
            color: colors.white,
            style: { height: 30 }
          }}
        />
      </View>
      <View style={styles.contentContainer}>
        {mainMenuLinksProps.map(({ linkProps, label }, i) =>
          'navigateTo' in linkProps ? (
            <Link_C key={i} navigateTo={linkProps.navigateTo} style={drawerLinkStyles}>
              {label}
            </Link_C>
          ) : (
            <Link_C
              key={i}
              linkUrl={linkProps.linkUrl}
              newTab
              externalLink
              style={drawerLinkStyles}
            >
              {label}
            </Link_C>
          )
        )}
      </View>
    </DrawerContentScrollView>
  )
}

const drawerLinkStyles: PressableStyleExtension = {
  elem: {
    paddingHorizontal: 12
  },
  text: {
    ...textSizes.size3,
    padding: 12,
    fontFamily: 'OpenSans',
    fontWeight: '600SemiBold',
    textTransform: 'uppercase',
    color: colors.white,
    borderBottomColor: colors.gray600,
    borderBottomWidth: 1
  }
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: presets.headerFooter
  },
  closeButtonContainer: {
    alignItems: 'flex-end',
    padding: 16
  },
  contentContainer: {
    paddingHorizontal: 24
  },
  scroll: {
    height: '100%',
    width: '100%'
  },
  scrollContent: {
    flexGrow: 1
  }
})
