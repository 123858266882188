import { StartType } from '../../../services/data/data-types/general-data-types.type'
import { TrackBasic } from '../../../services/data/data-types/local-data-types/track-basic.type'
import { AustralianState } from '../../../types/map.type'
import { Option } from '../../Base/Select/Select'

const ageOptions = ['2YO', '3YO', '4YO', '3YO and older', '4YO and older'] as const
export type AgeOptions = (typeof ageOptions)[number]

export const ageSelectOptions: Option<undefined | AgeOptions>[] = [
  { label: 'Age', value: undefined },
  { label: '2YO', value: '2YO' },
  { label: '3YO', value: '3YO' },
  { label: '4YO', value: '4YO' },
  { label: '3YO and older', value: '3YO and older' },
  { label: '4YO and older', value: '4YO and older' }
]

export type SexOptions = 'male' | 'female'

export const sexSelectOptions: Option<undefined | SexOptions>[] = [
  { label: 'Sex', value: undefined },
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' }
]

export type GaitOptions = 'pacers' | 'trotters'

export const gaitSelectOptions: Option<undefined | GaitOptions>[] = [
  { label: 'Gait', value: undefined },
  { label: 'Pacers', value: 'pacers' },
  { label: 'Trotters', value: 'trotters' }
]

export const startTypeSelectOptions: Option<undefined | StartType>[] = [
  { label: 'Start Type', value: undefined },
  { label: 'Mobile Start', value: 'Mobile Start' },
  { label: 'Standing Start', value: 'Standing Start' }
]

export type PrizeMoneyOptions = '0-4999' | '5k-9999' | '10k-25k' | '25k-50k' | '50k+'

export const prizeMoneySelectOptions: Option<undefined | PrizeMoneyOptions>[] = [
  { label: 'Prize Money', value: undefined },
  { label: '0-4999', value: '0-4999' },
  { label: '5k-9999', value: '5k-9999' },
  { label: '10k-25k', value: '10k-25k' },
  { label: '25k-50k', value: '25k-50k' },
  { label: '50k+', value: '50k+' }
]

export type DistanceOptions = undefined | '1800' | '1801-2399' | '2400+'

export const distanceOptions: Option<DistanceOptions>[] = [
  { label: 'Distance', value: undefined },
  { label: 'Up to 1800m', value: '1800' },
  { label: '1801m - 2399m', value: '1801-2399' },
  { label: '2400m+', value: '2400+' }
]

export type ProgramSearchFilter = {
  state?: AustralianState
  fromDate: Date
  toDate: Date
  track?: TrackBasic
  includeTrial?: boolean
  gait?: GaitOptions
  age?: AgeOptions
  distance?: DistanceOptions
  sex?: SexOptions
  prizeMoney?: PrizeMoneyOptions
  startType?: StartType
  rating?: number[]
}
