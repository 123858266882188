import { useFonts } from '@expo-google-fonts/open-sans'
import { NavigationContainer } from '@react-navigation/native'

import { LoadingSpinnerOverlay_C } from './components/Base/LoadingSpinnerOverlay/LoadingSpinnerOverlay'
import { DrawerContent_C } from './components/Partials/Drawer/DrawerContent'
import { GlobalProvider } from './global-context'
import { loopObject } from './helper-functions'
import { linkingOptions, MainNavigator, screens } from './services/routes/screens'
import { openSansFonts } from './styles/fonts'

export default function App() {
  const [fontsLoaded] = useFonts(openSansFonts)

  if (!fontsLoaded) {
    return null
  }

  return (
    <GlobalProvider>
      <NavigationContainer linking={linkingOptions}>
        <MainNavigator.Navigator
          backBehavior="history"
          defaultStatus="closed"
          drawerContent={() => <DrawerContent_C />}
          initialRouteName="Home"
          screenOptions={{
            headerShown: false,
            drawerType: 'front',
            drawerPosition: 'right'
          }}
        >
          {loopObject(screens, (name, { component, title, initialParams }) => {
            return (
              <MainNavigator.Screen
                {...{
                  key: name,
                  name,
                  options: { title },
                  component,
                  initialParams
                }}
              />
            )
          })}
        </MainNavigator.Navigator>

        <LoadingSpinnerOverlay_C />
      </NavigationContainer>
    </GlobalProvider>
  )
}
