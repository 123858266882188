import { fetchData } from '../api-fetch-request'
import { Pagination } from '../data-types/general-data-types.type'

export type HorseResultFetchResponse = {
  meta: {
    code: number
    status: string
  }
  pagination: {
    limit: number
    total: number
    page: number
    pages: number
  }
  horses: {
    formatted_name: string
    slug: string
    gender?: string
    gait?: string
    foaling_date: Date
    colour?: string
    microchip: string
    freezebrand: string
  }[]
}

export type HorseResultResponse = {
  meta: {
    code: number
    status: string
  }
  pagination: Pagination
  horses: {
    name: string
    slug: string
    gender?: string
    gait?: string
    foalingDate: Date
    colour?: string
    microchip: string
    freezebrand: string
  }[]
}

export const getSearchHorseResults = ({
  input,
  model,
  page
}: {
  input: string
  model?: string
  page?: number
}) => {
  const endpoint = `search/horse/${model}?query=${input}&pagination[limit]=25&pagination[page]=${page ?? 1}`

  return fetchData<HorseResultResponse, HorseResultFetchResponse>({ endpoint, convertResponse })

  function convertResponse(payload: HorseResultFetchResponse): HorseResultResponse {
    return {
      meta: payload.meta,
      pagination: {
        perPage: 25,
        totalPages: payload.pagination.pages,
        activePageNumber: payload.pagination.page,
        totalRecords: payload.pagination.total
      },
      horses: payload.horses.map((horse) => ({
        slug: horse.slug,
        name: horse.formatted_name,
        gender: horse.gender,
        gait: horse.gait,
        foalingDate: horse.foaling_date,
        microchip: horse.microchip,
        freezebrand: horse.freezebrand
      }))
    }
  }
}
