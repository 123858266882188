import { capitalize } from 'lodash'
import { fetchData } from '../api-fetch-request'
import {
  ResponseSearchHit,
  SearchAutocompleteFetchResponse,
  SearchAutocompleteResponse,
  SearchFetchResponse,
  SearchResponse
} from '../data-types/fetch-response-types/search-response.type'

const SEARCH_MODEL_TYPES = [
  'article',
  'driver',
  'horse',
  'page',
  'race',
  'track',
  'trainer',
  'freezebrand',
  'microchip'
] as const

export type SearchModelType = (typeof SEARCH_MODEL_TYPES)[number]

type SearchParamValues = {
  input: string
  page?: number
  model?: SearchModelType
}

export function getSearchResults(values: SearchParamValues): Promise<SearchResponse> {
  const model = values.model ? `/${values.model}?` : '?'
  const endpoint = `search${model}query=${
    values.input
  }&pagination[limit]=25&pagination[page]=${values.page ?? 1}`

  return fetchData<SearchResponse, SearchFetchResponse>({
    endpoint,
    convertResponse,
    validationCompareObject: undefined
  })

  function convertResponse(payload: SearchFetchResponse): SearchResponse {
    const { pagination, hits, search } = payload
    return {
      pagination: {
        perPage: pagination.limit,
        totalPages: pagination.pages,
        activePageNumber: pagination.page,
        totalRecords: pagination.total
      },
      hits: convertSearchHits(hits),
      search: {
        took: search.took,
        maxScore: search.max_score,
        didYouMean: search.did_you_mean
      }
    } as SearchResponse
  }
}

function convertSearchHits(hits: ResponseSearchHit[]) {
  return hits.map((hit) => {
    switch (hit.type) {
      case 'driver':
        return {
          type: hit.type,
          driver: {
            name: hit.driver.display_name,
            shortName: hit.driver.mobile_display_name,
            slug: hit.driver.slug
          }
        }
      case 'horse':
        return {
          type: hit.type,
          horse: {
            name: hit.horse.formatted_name,
            slug: hit.horse.slug,
            gender: hit.horse?.gender,
            gait: hit.horse?.gait,
            foalingDate: new Date(hit.horse.foaling_date),
            colour: hit.horse?.colour
          }
        }
      case 'page':
        return {
          type: hit.type,
          page: {
            title: hit.page.title,
            slug: hit.page.slug,
            content: hit.page.content
          }
        }
      case 'article':
        return {
          type: hit.type,
          article: {
            id: hit.article.id,
            slug: hit.article.slug,
            headline: hit.article.headline,
            author: hit.article.author,
            content: hit.article.content,
            published: hit.article.published
          }
        }
      case 'trainer':
        return {
          type: hit.type,
          trainer: {
            name: hit.trainer.display_name,
            shortName: hit.trainer.mobile_display_name,
            slug: hit.trainer.slug
          }
        }

      case 'track':
        return {
          type: hit.type,
          track: {
            name: hit.track.desktop_display_name,
            shortName: hit.track.mobile_display_name,
            slug: hit.track.slug,
            state: hit.track.state,
            about: hit.track.about
          }
        }
      case 'race':
        return {
          type: hit.type,
          race: {
            name: hit.race.name,
            slug: hit.race.slug,
            startTime: hit.race?.scheduled_start_time
              ? new Date(hit.race?.scheduled_start_time)
              : undefined,
            distance: hit.race?.distance,
            raceNumber: hit.race?.race_number
          }
        }

      default:
        return null
    }
  })
}

export function getSearchAutocomplete(values: { input: string; model?: string }) {
  const model = values.model ? `&model=${values.model}` : ''
  const endpoint = `search/autocomplete?query=${values.input}${model}`
  return fetchData<SearchAutocompleteResponse, SearchAutocompleteFetchResponse>({
    endpoint,
    convertResponse: ({ search, hits }: SearchAutocompleteFetchResponse) => ({
      search: {
        took: search.took,
        maxScore: search.max_score
      },
      hits: hits.map((hit) => ({
        score: hit.score,
        name: capitalize(hit.name)
      }))
    })
  })
}
