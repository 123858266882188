// cspell:word Dont
import { HorseFetchResponse } from '../data-types/fetch-response-types/horse-response.type'

export const horseCompare: HorseFetchResponse = {
  meta: {
    status: 200,
    code: 'OK'
  },
  horse: {
    id: 9665,
    formatted_name: 'Dont Yoube Naughty',
    gender: 'MARE',
    colour: 'BROWN/BLACK',
    gait: 'PACER',
    foaling_date: '2017-11-26',

    freezebrand: 'S123456',
    microchip: '123456789012345',

    sire: {
      name: 'Well Said USA',
      slug: '/well-said-usa'
    },
    dam: {
      name: 'Rigaleto Franco NZ',
      slug: '/rigaleto-franco-nz'
    },
    bm_sire: {
      name: 'Live Or Die USA',
      slug: '/live-or-die-usa'
    },
    hwoe_assessment: 'L2 (H$2550.50)',
    deceased: false,
    breeder: 'L A Collins, D Webb',
    slug: '/dont-yoube-naughty',
    assessment: 'NR30',
    owner: {
      display_name: 'L A Collins, D Webb',
      mobile_display_name: 'L A Collins, D Webb',
      rise_api_id: '851869BCE78536D1E055005E00000019',
      slug: '/l-a-collins-d-webb'
    },
    trainer: {
      display_name: 'Lynne Collins',
      mobile_display_name: 'L A Collins',
      rise_api_id: '851869BDD83D36D1E055005E00000019',
      slug: '/lynne-collins'
    },
    futurity_eligibility: [
      {
        title: 'The Eureka',
        placeholder_image_url:
          'https://api.staging.harness.au/onspace/media/assets/placeholder/eyJfcmFpbHMiOnsibWVzc2FnZSI6Ild6STNOamt3TVN3d1hRPT0iLCJleHAiOm51bGwsInB1ciI6Im9uc3BhY2VfbWVkaWFfYXNzZXRfaWQifX0=--e6f0d35c8be6bd5a4de7169efd9f1e4938ac7ae4e501db479ccbe95e9d72679b/the-eureka.webp',
        viewer_sources: [
          {
            url: 'https://api.staging.harness.au/onspace/media/assets/eyJfcmFpbHMiOnsibWVzc2FnZSI6Ild6STNOamt3TVN3d1hRPT0iLCJleHAiOm51bGwsInB1ciI6Im9uc3BhY2VfbWVkaWFfYXNzZXRfaWQifX0=--e6f0d35c8be6bd5a4de7169efd9f1e4938ac7ae4e501db479ccbe95e9d72679b/the-eureka.webp'
          }
        ]
      }
    ]
  },
  last_season_summary: {
    starts: 36,
    wins: 0,
    seconds: 4,
    thirds: 3,
    best_win_mile_rate: '',
    stakes: 5655
  },
  current_season_summary: {
    starts: 2,
    wins: 0,
    seconds: 0,
    thirds: 0,
    best_win_mile_rate: '',
    stakes: 120
  },
  lifetime_summary: {
    starts: 82,
    wins: 2,
    seconds: 4,
    thirds: 5,
    best_win_mile_rate: '2:01.7',
    stakes: 14683
  },
  upcoming_races: [
    {
      woo_average_odds: '41.00',
      forecast_odds: null,
      barrier: 'Fr1',
      meeting: {
        date: '2024-01-15',
        day_night_twilight: 'D',
        is_trial: false
      },
      track: {
        desktop_display_name: 'Albion Park',
        mobile_display_name: 'Albion Park',
        slug: '/albion-park',
        published: true
      },
      race: {
        number: 1,
        distance: 1660,
        start_type: 'MS',
        time_mile_rate: null,
        has_replay: false
      },
      trainer: {
        display_name: 'Lynne Collins',
        mobile_display_name: 'L A Collins',
        slug: '/lynne-collins'
      },
      driver: {
        display_name: 'Alanah Richardson',
        mobile_display_name: 'A K Richardson',
        slug: '/alanah-richardson'
      }
    }
  ],
  performance_records: [
    {
      place: '7',
      formatted_handicap: 'Fr2',
      prizemoney: 60.0,
      margin: '22.3m',
      starting_price: '71.00',
      barrier: 'Fr2',
      meeting: {
        date: '2024-01-11',
        day_night_twilight: 'D',
        is_trial: false
      },
      track: {
        desktop_display_name: 'Redcliffe',
        mobile_display_name: 'Redcliffe',
        slug: '/redcliffe',
        published: true
      },
      race: {
        number: 2,
        distance: 1780,
        start_type: 'MS',
        time_mile_rate: '1:59.9',
        has_replay: false
      },
      trainer: {
        display_name: 'Lynne Collins',
        mobile_display_name: 'L A Collins',
        slug: '/lynne-collins'
      },
      driver: {
        display_name: 'Alanah Richardson',
        mobile_display_name: 'A K Richardson',
        slug: '/alanah-richardson'
      }
    },
    {
      place: '9',
      formatted_handicap: 'Fr2',
      prizemoney: 60.0,
      margin: '31.2m',
      starting_price: '101.00',
      barrier: 'Fr2',
      meeting: {
        date: '2024-01-04',
        day_night_twilight: 'D',
        is_trial: false
      },
      track: {
        desktop_display_name: 'Redcliffe',
        mobile_display_name: 'Redcliffe',
        slug: '/redcliffe',
        published: true
      },
      race: {
        number: 5,
        distance: 1780,
        start_type: 'MS',
        time_mile_rate: '2:00.6',
        has_replay: false
      },
      trainer: {
        display_name: 'Lynne Collins',
        mobile_display_name: 'L A Collins',
        slug: '/lynne-collins'
      },
      driver: {
        display_name: 'Adam Richardson',
        mobile_display_name: 'A P Richardson',
        slug: '/adam-richardson'
      }
    },
    {
      place: '7',
      formatted_handicap: 'Fr3',
      prizemoney: 60.0,
      margin: '12.5m',
      starting_price: '51.00',
      barrier: 'Fr3',
      meeting: {
        date: '2023-12-28',
        day_night_twilight: 'D',
        is_trial: false
      },
      track: {
        desktop_display_name: 'Redcliffe',
        mobile_display_name: 'Redcliffe',
        slug: '/redcliffe',
        published: true
      },
      race: {
        number: 5,
        distance: 2040,
        start_type: 'MS',
        time_mile_rate: '2:02.6',
        has_replay: false
      },
      trainer: {
        display_name: 'Lynne Collins',
        mobile_display_name: 'L A Collins',
        slug: '/lynne-collins'
      },
      driver: {
        display_name: 'Alanah Richardson',
        mobile_display_name: 'A K Richardson',
        slug: '/alanah-richardson'
      }
    },
    {
      place: '10',
      formatted_handicap: 'Fr2',
      prizemoney: 60.0,
      margin: '46.7m',
      starting_price: '21.00',
      barrier: 'Fr2',
      meeting: {
        date: '2023-12-14',
        day_night_twilight: 'D',
        is_trial: false
      },
      track: {
        desktop_display_name: 'Redcliffe',
        mobile_display_name: 'Redcliffe',
        slug: '/redcliffe',
        published: true
      },
      race: {
        number: 8,
        distance: 1780,
        start_type: 'MS',
        time_mile_rate: '2:00.3',
        has_replay: false
      },
      trainer: {
        display_name: 'Lynne Collins',
        mobile_display_name: 'L A Collins',
        slug: '/lynne-collins'
      },
      driver: {
        display_name: 'Angus Garrard',
        mobile_display_name: 'A R Garrard',
        slug: '/angus-garrard'
      }
    },
    {
      place: '2',
      formatted_handicap: 'Fr1',
      prizemoney: 677.0,
      margin: '3.6m',
      starting_price: '8.00',
      barrier: 'Fr1',
      meeting: {
        date: '2023-12-10',
        day_night_twilight: 'D',
        is_trial: false
      },
      track: {
        desktop_display_name: 'Marburg',
        mobile_display_name: 'Marburg',
        slug: '/marburg',
        published: true
      },
      race: {
        number: 5,
        distance: 1850,
        start_type: 'MS',
        time_mile_rate: '2:02.6',
        has_replay: true
      },
      trainer: {
        display_name: 'Lynne Collins',
        mobile_display_name: 'L A Collins',
        slug: '/lynne-collins'
      },
      driver: {
        display_name: 'Alanah Richardson',
        mobile_display_name: 'A K Richardson',
        slug: '/alanah-richardson'
      }
    },
    {
      place: '7',
      formatted_handicap: 'Fr3',
      prizemoney: 60.0,
      margin: '41.0m',
      starting_price: '101.00',
      barrier: 'Fr3',
      meeting: {
        date: '2023-11-30',
        day_night_twilight: 'D',
        is_trial: false
      },
      track: {
        desktop_display_name: 'Redcliffe',
        mobile_display_name: 'Redcliffe',
        slug: '/redcliffe',
        published: true
      },
      race: {
        number: 4,
        distance: 2040,
        start_type: 'MS',
        time_mile_rate: '2:02.1',
        has_replay: true
      },
      trainer: {
        display_name: 'Lynne Collins',
        mobile_display_name: 'L A Collins',
        slug: '/lynne-collins'
      },
      driver: {
        display_name: 'Paige Bevan',
        mobile_display_name: 'P M Bevan',
        slug: '/paige-bevan'
      }
    },
    {
      place: '7',
      formatted_handicap: 'Fr3',
      prizemoney: 60.0,
      margin: '19.0m',
      starting_price: '26.00',
      barrier: 'Fr3',
      meeting: {
        date: '2023-11-27',
        day_night_twilight: 'N',
        is_trial: false
      },
      track: {
        desktop_display_name: 'Redcliffe',
        mobile_display_name: 'Redcliffe',
        slug: '/redcliffe',
        published: true
      },
      race: {
        number: 3,
        distance: 1780,
        start_type: 'MS',
        time_mile_rate: '2:01.8',
        has_replay: true
      },
      trainer: {
        display_name: 'Lynne Collins',
        mobile_display_name: 'L A Collins',
        slug: '/lynne-collins'
      },
      driver: {
        display_name: 'Paige Bevan',
        mobile_display_name: 'P M Bevan',
        slug: '/paige-bevan'
      }
    },
    {
      place: '6',
      formatted_handicap: 'Fr2',
      prizemoney: 60.0,
      margin: '30.0m',
      starting_price: '21.00',
      barrier: 'Fr2',
      meeting: {
        date: '2023-11-19',
        day_night_twilight: 'T',
        is_trial: false
      },
      track: {
        desktop_display_name: 'Redcliffe',
        mobile_display_name: 'Redcliffe',
        slug: '/redcliffe',
        published: true
      },
      race: {
        number: 6,
        distance: 1780,
        start_type: 'MS',
        time_mile_rate: '1:59.6',
        has_replay: false
      },
      trainer: {
        display_name: 'Lynne Collins',
        mobile_display_name: 'L A Collins',
        slug: '/lynne-collins'
      },
      driver: {
        display_name: 'Chloe Butler',
        mobile_display_name: 'C J Butler',
        slug: '/chloe-butler'
      }
    },
    {
      place: '4',
      formatted_handicap: 'Fr1',
      prizemoney: 226.0,
      margin: '17.7m',
      starting_price: '34.00',
      barrier: 'Fr1',
      meeting: {
        date: '2023-11-12',
        day_night_twilight: 'N',
        is_trial: false
      },
      track: {
        desktop_display_name: 'Redcliffe',
        mobile_display_name: 'Redcliffe',
        slug: '/redcliffe',
        published: true
      },
      race: {
        number: 7,
        distance: 1780,
        start_type: 'MS',
        time_mile_rate: '2:00.6',
        has_replay: false
      },
      trainer: {
        display_name: 'Lynne Collins',
        mobile_display_name: 'L A Collins',
        slug: '/lynne-collins'
      },
      driver: {
        display_name: 'Leonard Cain',
        mobile_display_name: 'L T Cain',
        slug: '/leonard-cain'
      }
    },
    {
      place: '8',
      formatted_handicap: 'Fr4',
      prizemoney: 60.0,
      margin: '33.9m',
      starting_price: '31.00',
      barrier: 'Fr4',
      meeting: {
        date: '2023-11-05',
        day_night_twilight: 'D',
        is_trial: false
      },
      track: {
        desktop_display_name: 'Marburg',
        mobile_display_name: 'Marburg',
        slug: '/marburg',
        published: true
      },
      race: {
        number: 2,
        distance: 1850,
        start_type: 'MS',
        time_mile_rate: '2:02.2',
        has_replay: false
      },
      trainer: {
        display_name: 'Lynne Collins',
        mobile_display_name: 'L A Collins',
        slug: '/lynne-collins'
      },
      driver: {
        display_name: 'Alanah Richardson',
        mobile_display_name: 'A K Richardson',
        slug: '/alanah-richardson'
      }
    }
  ]
}
