import { RouteProp, useRoute } from '@react-navigation/native'
import { Fragment, useEffect, useState } from 'react'
import { Platform, Pressable, Text, View } from 'react-native'

import {
  SearchAutocomplete,
  SearchHit,
  SearchHitHorse
} from '../../../services/data/data-types/fetch-response-types/search-response.type'
import { Pagination } from '../../../services/data/data-types/general-data-types.type'
import {
  SearchModelType,
  getSearchAutocomplete,
  getSearchResults
} from '../../../services/data/request-functions/search-request'
import { createStyles } from '../../../services/styles/breakpoint-styles.service'
import { presets } from '../../../styles/colors'
import { Heading_C } from '../../Base/Heading/Heading'
import { Icon_C } from '../../Base/Icon/Icon'
import { ScrollHorizontal_C } from '../../Base/ScrollHorizontal/ScrollHorizontal'
import { Text_C } from '../../Base/Text/Text'
import { Card_C } from '../../Layout/Card/Card'
import { withScreenWrapper } from '../../Layout/ScreenWrapper/ScreenWrapper'
import { Pagination_C } from '../../Partials/Pagination/Pagination'
import { LatestNews_C } from '../Videos/components/LatestNews'
import { SearchBar_C } from './components/SearchBar'
import { SearchItem_C } from './components/SearchItem'
import { SEARCH_OPTIONS, SearchParams } from './utils'
import { Metadata } from '../../Partials/Metadata/Metadata'
import { getSearchHorseResults } from '../../../services/data/request-functions/search-horse-request'

const DEFAULT_PAGINATION: Pagination = {
  activePageNumber: 1,
  perPage: 25,
  totalPages: 1,
  totalRecords: 0
}

function updateSearchParams(values: { input?: string; model?: SearchModelType; page?: number }) {
  if (Platform.OS !== 'web') return

  const newUrl = new URL(window.location.href)

  if (values.input) {
    newUrl.searchParams.set('search', values.input)
  } else {
    newUrl.searchParams.delete('search')
  }

  if (values.model) {
    newUrl.searchParams.set('type', values.model ?? '')
  } else {
    newUrl.searchParams.delete('type')
  }

  if (values.page) {
    newUrl.searchParams.set('page', values.page.toString())
  } else {
    newUrl.searchParams.delete('page')
  }

  window.history.pushState({}, '', newUrl.toString())
}

export default function Search_C(): JSX.Element {
  return withScreenWrapper<'Search'>({
    aside: () => <LatestNews_C />,
    view: () => {
      const { params } = useRoute<RouteProp<SearchParams>>()

      const [isInitState, setInitState] = useState(true)
      const [pagination, setPagination] = useState<Pagination>(DEFAULT_PAGINATION)

      const [input, setInput] = useState<string>(params?.search || '')
      const [modelType, setModelType] = useState<SearchModelType | undefined>(params.type)

      const [isLoading, setLoading] = useState(false)
      const [hasError, setHasError] = useState(false)
      const [hasBeenSearched, setHasBeenSearched] = useState(false)
      const [results, setResults] = useState<SearchHit[]>()
      const [autoHits, setAutoHits] = useState<SearchAutocomplete[]>([])

      useEffect(() => {
        updateSearchParams({ input, model: modelType })
      }, [input, modelType])

      useEffect(() => {
        if (!params.search) return

        search({
          input: params.search,
          model: params.type,
          page: params.page
        })
      }, [])

      useEffect(() => {
        if (isInitState || isLoading || input.length <= 2) return

        if (params.type === 'freezebrand' || params.type === 'microchip') return

        if (hasBeenSearched) return

        getSearchAutocomplete({ input, model: modelType })
          .then(({ hits }) => setAutoHits(hits))
          .catch(() => setAutoHits([]))
      }, [input, isInitState, isLoading, modelType])

      const searchResults = async (values: {
        input: string
        model?: SearchModelType
        page?: number
      }) => {
        try {
          const { hits, pagination } = await getSearchResults(values)
          setResults(hits)
          setPagination(pagination)
          setLoading(false)
        } catch (error) {
          setHasError(true)
          setLoading(false)
        }
      }

      const searchHorseType = async (values: {
        input: string
        model?: SearchModelType
        page?: number
      }) => {
        try {
          const { pagination: paginationResponse, horses } = await getSearchHorseResults(values)
          const results: SearchHitHorse[] = horses.map((horse) => ({
            score: 1,
            type: 'horse',
            horse: horse
          }))
          setResults(results)
          setPagination(paginationResponse)
        } catch (error) {
          setHasError(true)
        } finally {
          setLoading(false)
        }
      }

      function search(values: { input: string; model?: SearchModelType; page?: number }) {
        if (input.length <= 2) return

        updateSearchParams(values)

        if (!values.page) {
          setResults(undefined)
          setLoading(true)
        }

        setAutoHits([])
        setHasError(false)
        setLoading(true)

        if (values.model === 'freezebrand' || values.model === 'microchip') {
          searchHorseType(values)
        } else {
          searchResults(values)
        }

        setHasBeenSearched(true)
      }

      function autosearchInput({ input }: { input: string }) {
        setInput(input)
        search({ input, model: modelType })
      }

      function handleOnPageChange(page: number) {
        search({ input, model: modelType, page })
      }

      const hasResults = !!results && results.length > 0

      return (
        <>
          <Metadata
            title="Search"
            description="Search for all news, tracks, horses, drivers, trainers and more on harness.org.au"
          />

          <Card_C>
            <Heading_C styleType="h1">Search</Heading_C>
            <View style={{ marginTop: 10, zIndex: 1 }}>
              <SearchBar_C
                autoHits={autoHits}
                closeAutocomplete={() => setAutoHits([])}
                inputValue={input}
                onInputChange={(value: string) => {
                  setInitState(false)
                  setHasBeenSearched(false)
                  setInput(value)
                }}
                onSubmit={(inputValue: string) => search({ input: inputValue, model: modelType })}
                searchInput={(input) => autosearchInput({ input })}
              />

              <View style={styles.container}>
                <ScrollHorizontal_C contentContainerStyle={styles.radioContainer}>
                  {SEARCH_OPTIONS.map((option, idx) => (
                    <Pressable
                      key={idx}
                      onPress={() => setModelType(option.value)}
                      style={styles.radioButton}
                    >
                      <View style={styles.radioButtonBorder}>
                        <View
                          style={{
                            ...styles.radioButtonFill,
                            backgroundColor: modelType === option.value ? 'black' : 'white'
                          }}
                        />
                      </View>

                      <Text>{option.name}</Text>
                    </Pressable>
                  ))}
                </ScrollHorizontal_C>
              </View>
            </View>
          </Card_C>

          <Card_C style={{ zIndex: -1 }}>
            {!hasResults && isInitState && !isLoading && (
              <View style={styles.resultsContainer}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    columnGap: 20
                  }}
                >
                  <Icon_C name="search" style={{ width: 28, height: 28 }} color="#888" />
                  <Icon_C name="article" style={{ width: 28, height: 28 }} color="#888" />
                  <Icon_C
                    name="calendar2"
                    style={{ width: 22, height: 22 }}
                    color="#888"
                    strokeWidth={2}
                  />
                </View>

                <Text_C style={{ textAlign: 'center', color: '#555' }}>
                  Search the latest news, races, track information and more
                </Text_C>
              </View>
            )}

            {isLoading && (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingVertical: 40,
                  columnGap: 5
                }}
              >
                <Icon_C name="search" style={{ width: 28, height: 28 }} color="#888" />
                <Text_C>Searching...</Text_C>
              </View>
            )}

            {!isLoading && !isInitState && hasError && (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingVertical: 40,
                  columnGap: 5
                }}
              >
                <Text_C style={{ textAlign: 'center' }}>Could not load search results</Text_C>
              </View>
            )}

            {!isLoading && !isInitState && !hasError && !hasResults && (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingVertical: 40,
                  columnGap: 5
                }}
              >
                <Icon_C name="search" style={{ width: 28, height: 28 }} color="#888" />
                <Text_C>No search results could be found</Text_C>
              </View>
            )}

            {!isLoading && !hasError && hasResults && (
              <>
                {pagination && (
                  <Pagination_C
                    activePageNumber={pagination.activePageNumber}
                    perPage={pagination.perPage}
                    totalPages={pagination.totalPages}
                    totalRecords={pagination.totalRecords}
                    onPageChange={handleOnPageChange}
                  />
                )}

                <View style={{ marginVertical: 20 }}>
                  {results.map((result, idx) => (
                    <Fragment key={idx}>
                      {idx != 0 && (
                        <View
                          style={{
                            width: '100%',
                            height: 1,
                            backgroundColor: presets.border
                          }}
                        />
                      )}
                      <SearchItem_C hit={result} />
                    </Fragment>
                  ))}
                </View>

                {pagination && (
                  <Pagination_C
                    activePageNumber={pagination.activePageNumber}
                    perPage={pagination.perPage}
                    totalPages={pagination.totalPages}
                    totalRecords={pagination.totalRecords}
                    onPageChange={handleOnPageChange}
                  />
                )}
              </>
            )}
          </Card_C>
        </>
      )
    }
  })
}

const styles = createStyles({
  container: {
    position: 'relative',
    marginTop: 15
  },
  radioContainer: {
    gap: 10
  },
  radioButtonBorder: {
    width: 16.5,
    height: 16,
    padding: 2,
    borderColor: 'black',
    borderRadius: 40,
    borderWidth: 1,
    overflow: 'hidden'
  },
  radioButtonFill: {
    borderRadius: 40,
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  radioButton: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5
  },
  resultsContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 'auto',
    gap: 10,
    maxWidth: 300,
    paddingVertical: 40
  }
})
