/* eslint-disable react/jsx-key */
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { View } from 'react-native'
import { formatCurrency, isNumeric } from '../../../../helper-functions'
import { Pagination } from '../../../../services/data/data-types/general-data-types.type'
import { LatestResult } from '../../../../services/data/data-types/local-data-types/horse.type'
import { getHorsePerformanceData } from '../../../../services/data/request-functions/horse-performance-request'
import {
  breakpointBelow,
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import { tabletBreakpoint } from '../../../../services/styles/dependencies/style-constants'
import { textSizes } from '../../../../styles/text-sizes'
import { Heading_C } from '../../../Base/Heading/Heading'
import { TableStyles, Table_C } from '../../../Base/Table/Table'
import { TableHeading, TableRow } from '../../../Base/Table/table.type'
import { Text_C } from '../../../Base/Text/Text'
import { Card_C } from '../../../Layout/Card/Card'
import { LinkOrText_C } from '../../../Partials/LinkOrText'
import { Pagination_C } from '../../../Partials/Pagination/Pagination'
import { PerPageOption, PerPageSelect } from '../../../Partials/Pagination/components/PerPageSelect'
import { TableCellRaceLink_C } from '../../../Partials/_meeting-components/TableCellRaceLink'

type Props = { latestResults: LatestResult[]; slug: string }

export function LatestResults_C(props: Props) {
  const [latestResults, setLatestResults] = useState(props.latestResults)
  const [pagination, setPagination] = useState<Pagination>()

  useEffect(() => {
    getPaginatedPerformanceData()
  }, [props.slug])

  function getPaginatedPerformanceData(param?: { pageNumber?: number; perPage?: PerPageOption }) {
    const { pageNumber, perPage } = param ?? {}
    getHorsePerformanceData({
      horseSlug: props.slug,
      activePageNumber: pageNumber ?? 1,
      perPage
    }).then(({ horseLatestResults: horsePerformanceRecords, pagination }) => {
      setPagination(pagination)
      setLatestResults(horsePerformanceRecords)
    })
  }

  const styles = useBreakpointStyles({ styles: breakpointStyles })

  const isMobileLayout = breakpointBelow(tabletBreakpoint)

  const { performanceHeadings, performanceRows } = setup()

  return (
    <>
      <Card_C style={styles.racesCard}>
        <View style={styles.raceGroup}>
          <Heading_C styleType="h4" style={breakpointStyles.raceHeading}>
            Latest Results
          </Heading_C>

          <PerPageSelect
            initialSelection={pagination?.perPage}
            onSelect={(perPage) => getPaginatedPerformanceData({ perPage })}
          />

          {pagination && (
            <Pagination_C
              options={{
                infoSide: 'left'
              }}
              {...pagination}
              onPageChange={(pageNumber) =>
                getPaginatedPerformanceData({
                  pageNumber,
                  perPage: pagination.perPage
                })
              }
            />
          )}

          <Table_C
            styleType="lightHeaderBorders"
            headings={performanceHeadings}
            rows={performanceRows}
            styles={tableStyle}
          />
          {pagination && (
            <Pagination_C
              options={{
                infoSide: 'left'
              }}
              {...pagination}
              onPageChange={(pageNumber) =>
                getPaginatedPerformanceData({
                  pageNumber,
                  perPage: pagination.perPage
                })
              }
            />
          )}
        </View>
      </Card_C>
    </>
  )

  function setup() {
    const performanceHeadings: TableHeading[] = [
      { content: 'Meeting', colWidth: { width: 190 } },
      { content: 'Place', colWidth: { width: 70 } },
      'Distance',
      'Handicap',
      'Margin',
      'WMR',
      { content: 'Driver', colWidth: { width: 100 } },
      { content: 'Trainer', colWidth: { width: 100 } },
      'Price',
      'Prize Money'
    ]
    const performanceRows: TableRow[] = latestResults.map((recordRace) => {
      const placeNumber = isNumeric(recordRace.place) ? Number(recordRace.place) : undefined

      const { meetingDate, isTrial, meetingDayPhase, track, race } = recordRace

      return {
        cells: [
          !track.published ? (
            <Text_C
              style={{
                ...styles.trackText,
                ...(isTrial && { fontStyle: 'italic' })
              }}
            >
              {`${track.shortName ?? track.name} \n${format(
                new Date(meetingDate),
                'd/M/yy'
              )}${isTrial ? ' (T)' : ''}`}
            </Text_C>
          ) : (
            <TableCellRaceLink_C
              {...{
                date: meetingDate,
                dayPhaseLetter: meetingDayPhase,
                isTrial,
                trackName: track.shortName ?? track.name,
                trackSlug: track.slug,
                hasReplay: race.hasReplay,
                raceNumber: `${race.number}`
              }}
            />
          ),
          placeNumber,
          `${recordRace.race.distance}m ${recordRace.race.startType}`,
          recordRace.handicap,
          recordRace.margin,
          recordRace.race.mileRate,

          <LinkOrText_C
            {...{
              name:
                (isMobileLayout && recordRace?.driver?.shortName) ||
                recordRace?.driver?.name ||
                '---',
              navigateTo: !recordRace?.driver?.slug
                ? undefined
                : ['Driver', { driverSlug: recordRace.driver.slug }]
            }}
          />,
          <LinkOrText_C
            {...{
              name:
                (isMobileLayout && recordRace?.trainer?.shortName) ||
                recordRace?.trainer?.name ||
                '---',
              navigateTo: !recordRace?.trainer?.slug
                ? undefined
                : ['Trainer', { trainerSlug: recordRace.trainer.slug }]
            }}
          />,
          recordRace.startingPrice ? recordRace.startingPrice : '',
          recordRace.prizemoney ? formatCurrency(recordRace.prizemoney) : ''
        ]
      }
    })

    return { performanceHeadings, performanceRows }
  }
}

const breakpointStyles = createStyles({
  racesCard: {
    base: {
      gap: 40
    }
  },
  raceGroup: {
    base: { maxWidth: '100%', rowGap: 20 }
  },
  raceHeading: {
    base: { marginBottom: 16 }
  },
  trackText: {
    ...textSizes.size2
  }
})

const tableStyle: TableStyles = {
  tableContainer: { base: { minWidth: 'auto', flex: 1 } },
  table: { width: '100%' }
}
